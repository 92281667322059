import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CounterpartyEditForm,
  CounterpartyRating,
  IconBackTo,
  MissingDataGrid,
  TabPanel,
  Tabs,
  useTabs,
} from 'components';
import { themeOrange as theme } from 'theme';
import SwipeableViews from 'react-swipeable-views';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import { useState } from 'react';
import { CounterpartyRisks } from '../CounterpartyRisks';
import { PageTitle } from 'components/utils/pageTitle';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navBottom: {
      marginBottom: '12px',
    },
    span: {
      marginLeft: theme.spacing(1.6),
    },
    overflowFix: {
      '& > div': {
        overflowX: 'clip !important',
        '& > div > div': {
          overflow: 'inherit !important',
        },
      },
    },
  })
);

export const Counterparty = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { state, hash } = useLocation<{ tab?: string }>();

  const tabs = [
    {
      name: t('GeneralInfo'),
    },
    {
      name: t('Missing'),
    },
    {
      name: t('Rating'),
    },
    {
      name: t('POD FT'),
    },
  ];

  const getInitialTab = (
    state: { tab?: string } | null,
    hash: string,
    tabs: { name: string }[]
  ) => {
    window.history.replaceState({}, '');
    if (state?.tab) {
      return tabs.findIndex((item) => item.name === state.tab);
    }

    return hash === '#missing' ? 1 : hash === '#ratings' ? 2 : 0;
  };
  const initialTab = getInitialTab(state, hash, tabs);
  const tabsProps = useTabs(tabs, initialTab);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;
  const [counterpartyName, setCounterpartyName] = useState<string>();
  const {
    params: { inn },
  } = useRouteMatch<{ inn: string }>();

  const pageTitle = counterpartyName + ' - Bumblebee';
  PageTitle(pageTitle);

  const history = useHistory();
  const backHandler = () => {
    history.push(`/counterparties/${inn}`, { tab: t('Rating') });
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid className={classes.navBottom} item>
          <Route path="/counterparties/:inn">
            <Route path="/counterparties/:inn/rating/add" exact>
              <Button variant="iconButton" endIcon={<IconBackTo />} onClick={backHandler} />
              <span className={classes.span}>
                {t('Rating')} {counterpartyName}
              </span>
            </Route>
            <Route path={`/counterparties/:inn/rating/:id(\\d+)`} exact>
              <Button variant="iconButton" endIcon={<IconBackTo />} onClick={backHandler} />
              <span className={classes.span}>
                {t('Rating')} {counterpartyName}
              </span>
            </Route>
            <Route path="/counterparties/:inn/rating/compare" exact>
              <Button variant="iconButton" endIcon={<IconBackTo />} onClick={backHandler} />
              <span className={classes.span}>
                {t('Rating')} {counterpartyName}
              </span>
            </Route>
            <Route path="/counterparties/:inn" exact>
              <Button variant="iconButton" endIcon={<IconBackTo />} to="/counterparties" />
              <span className={classes.span}>{counterpartyName}</span>
            </Route>
          </Route>
        </Grid>
      </Grid>
      <Box mb={2.5}>
        <Tabs {...tabsProps} value={tabIndex} onChangeTab={onChangeTab} />
      </Box>
      <Grid item className={classes.overflowFix}>
        <SwipeableViews
          containerStyle={{
            transition: 'transform 0.6s ease-out 0s',
          }}
          springConfig={{
            duration: '0.6s',
            easeFunction: 'transform 0.6s ease-out 0s',
            delay: '0s',
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabIndex}
          onChangeIndex={onChangeTabIndex}
        >
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            {tabIndex === 0 && <CounterpartyEditForm setCounterpartyName={setCounterpartyName} />}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} dir={theme.direction}>
            {tabIndex === 1 && <MissingDataGrid debt={true} />}
          </TabPanel>
          <TabPanel value={tabIndex} index={2} dir={theme.direction}>
            {tabIndex === 2 && <CounterpartyRating />}
          </TabPanel>
          <TabPanel value={tabIndex} index={3} dir={theme.direction}>
            {tabIndex === 3 && <CounterpartyRisks />}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};
