import { Button, IconSprite } from 'components';
import { useCallback } from 'react';

export type RemoveButtonProps = {
  index: number;
  onRemove: (index: number) => void;
};

export const RemoveButton = (props: RemoveButtonProps) => {
  const { index, onRemove } = props;

  const handleOnRemove = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  return (
    <Button
      variant="iconButton"
      endIcon={<IconSprite icon={'delete'} width={'14px'} />}
      onClick={handleOnRemove}
    />
  );
};
