import { useBackendQuery, QueryOptions, MutationOptions, useBackendMutation } from '.';
import { RiskTypeHistoryItemViewModel, RiskTypeViewModel } from 'schema';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';

const entity = 'riskTypes';

const useQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;

  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

const getMutationUrl = <TRequest>(relativeUrl: string | ((form: TRequest) => string)) => {
  if (typeof relativeUrl === 'string') {
    return createUrlFromParts(entity, relativeUrl);
  }
  return (form: TRequest) => createUrlFromParts(entity, relativeUrl(form));
};

export const useCounterpartyRisksQuery = (
  inn: string,
  options?: QueryOptions<RiskTypeViewModel[], string>
) =>
  useQuery({
    relativeUrl: `${inn}`,
    options,
  });

export const useCounterpartyRisksHistoryQuery = (
  riskTypeId: number,
  options?: QueryOptions<RiskTypeHistoryItemViewModel[], string>
) =>
  useQuery({
    relativeUrl: `${riskTypeId}/history`,
    options,
  });

export const useCounterpartyRiskMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string | ((form: TRequest) => string),
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'PUT' }
) => useBackendMutation(getMutationUrl(relativeUrl), options);
