import { PagedList } from 'components';

export type ValidationProblemErrors = {
  [k: string]: string[];
};

export type ValidationProblemDetails = {
  errors: ValidationProblemErrors;
  type?: string;
  title?: string;
  status?: number;
  traceId?: string;
};

export enum CalculationMethod {
  Annuity = 'annuity',
  StraightLine = 'straightLine',
  Seasonal = 'seasonal',
  SuperSeasonal = 'superSeasonal',
}

export enum CalculationMethodType {
  Forward = 'forward',
  Reverse = 'reverse',
  ReverseIRR = 'reverseIRR',
}

export enum InsuranceFranchise {
  None = 'none',
  Unconditional = 'unconditional',
  Conditional = 'conditional',
}

export enum Telematics {
  Caesar = 'caesar',
  XPro = 'xpro',
  None = 'none',
  Krimistop = 'krimistop',
  XproKrimistop = 'xproKrimistop',
}

export enum AgentFeeRecipient {
  Entity = 'entity',
  Individual = 'individual',
  IndividualEntrepreneur = 'individualEntrepreneur',
}

export enum AgentFeeRefund {
  Evenly = 'evenly',
}

export enum AuditType {
  Desk = 'desk',
  Field = 'field',
  Mobile = 'mobile',
}

export enum AuditRegularity {
  Month1 = 'month1',
  Month3 = 'month3',
  Month6 = 'month6',
  Month12 = 'month12',
  Month24 = 'month24',
}

export type MonthPaymentOption = {
  number: number;
  isPayment: boolean;
  isPreferential: boolean;
  value: number;
};

export enum SeasonalPaymentType {
  MonthlyPayment = 'monthlyPayment',
  MainDebt = 'mainDebt',
}

export type SeasonalPaymentOptions = {
  date?: string;
  paymentType: SeasonalPaymentType;
  hasCyclicity: boolean;
};

export type CalculationInput = {
  calculationMethod: CalculationMethod;
  itemPrice?: number;
  leaseItemCost?: number;
  numberOfItems?: number;
  numberOfMonths?: number;
  prepayment?: Amount;
  itemDiscount?: Amount;
  subsidyVendor?: Amount;
  subsidyDealer?: Amount;
  agentFee?: Amount;
  isAgentFeeWithNds?: boolean;
  agentFeeRecipient: AgentFeeRecipient;
  insuranceRatePercents?: number;
  telematicsOneTimeAmount?: number;
  telematicsMonthlyAmount?: number;
  tradeFee?: number;
  residualValue?: Amount;
  marginPercents?: number;
  totalMargin?: number;
  cofPercents?: number;
  cofAddPercents?: number;
  vatPercents?: number;
  calculationMethodType?: CalculationMethodType;
  irrPercents?: number;
  rizeInPricePercents?: number;
  currencySale?: string;
  currencyRate?: number;
  currencyLease?: string;
  hasResidualValue?: boolean;
  hasInsurance?: boolean;
  agreement?: CalculationAgreement;
  seasonalPaymentOptions?: SeasonalPaymentOptions;
  paymentOptions?: MonthPaymentOption[];
  nomenclatures: QuotaNomenclatureViewModel[];
  lessee?: string;
};

export type CalculationAgreement = {
  leaseProduct?: string;
  brand?: string;
  category?: string;
  model?: string;
  insuranceFranchise?: InsuranceFranchise;
  franchiseAmount?: number;
  amount?: number;
  generalRatePercents?: number;
  year?: number;
  secondHand?: boolean;
  currencyCommission?: boolean;
  balanceHolder?: BalanceHolder;
  hasPropertyTax?: boolean;
  propertyTax?: Amount;
  hasVehicleRegistration?: boolean;
  telematics?: Telematics;
  krimistopTelematicsCount?: number;
  agent?: string;
  agentFeeRefund?: AgentFeeRefund;
  hasAudit?: boolean;
  auditType?: AuditType;
  auditRegularity?: AuditRegularity;
  isSubleasing?: boolean;
  hasFeePriceRule?: boolean;
  hasInsurancePriceRule?: boolean;
  hasTelematicsPriceRule?: boolean;
  hasSubsidyPriceRule?: boolean;
};

export type QuotaCalculationInput = CalculationInput & {
  dealer?: string;
  lesseeName?: string;
  insuranceCompany?: string;
  industryLeasingProductId?: number;
};

export type QuotaPayment = {
  insurance: number;
  mainDebt: number;
  mainDebtPayment: number;
  number: number;
  percentsPayment: number;
  telematics: number;
  agentFee: number;
  total: number;
  date?: string;
};

export type NomenclatureMargin = {
  code: string;
  margin: number;
};

export type CalculationResult = {
  model?: string;
  leaseSubject?: string;
  calculationMethod: CalculationMethod;
  assetCost: number;
  fundingAmount: number;
  itemPrice: number;
  leaseItemCost: number;
  monthlyPayment: number;
  saleCurrency: Currency;
  leaseCurrency: Currency;
  numberOfItems: number;
  numberOfMonths: number;
  payments: QuotaPayment[];
  discount?: number;
  fundingAmountNBV?: number;
  discountAmount?: number;
  subsidyDiscount?: number;
  subsidyAmount?: number;
  tradeFeeAmount: number;
  financingFeeAmount: number;
  agentFee?: number;
  agentFeeAmount?: number;
  agentFeeAmountNBV?: number;
  agentFeeRecipient?: AgentFeeRecipient;
  residualValue?: number;
  totalPaymentsAmount: number;
  initialFee: number;
  telematics?: Telematics;
  telematicsPayment?: number;
  insuranceCompany?: string;
  insuranceTotal?: number;
  insuranceMonthlyWithVat?: number;
  irr: number;
  avPayment: number;
  priceRizing: number;
  isInvalidPrizeRizing: boolean;
  nomenclatureMargins: NomenclatureMargin[];
  totalMargin?: number;
  marginErrorMessage?: string;
  originalMargin?: number;
  ratingGrade?: number;
  scoringModelName?: string;
  cofPercents: number;
};

export type CreatedQuotaResult = {
  quotaId: number;
  calculationResult: CalculationResult;
};

export const isCreatedQuota = (
  result: CreatedQuotaResult | CalculationResult | undefined,
): result is CreatedQuotaResult => {
  if (result === undefined) {
    return false;
  }
  return (result as CreatedQuotaResult).quotaId !== undefined;
};

export const isQuotaCalculation = (
  result: QuotaCalculationResult | CalculationResult | undefined,
): result is QuotaCalculationResult => {
  if (result === undefined) {
    return false;
  }
  return (result as QuotaCalculationResult).quotaId !== undefined;
};

export enum AmountType {
  Money = 'money',
  Percents = 'percents',
}

export type Amount = {
  type: AmountType;
  value: number;
};

export enum Currency {
  Ruble = 'ruble',
  Dollar = 'dollar',
  Euro = 'euro',
  Yuan = 'yuan',
}

export enum BalanceHolder {
  Lessor = 'lessor',
  Lessee = 'lessee',
}

export enum ApplicationRole {
  Admin = 'admin',
  SuperSalesManager = 'super_sales_manager',
  SalesManager = 'sales_manager',
  SalesSupport = 'sales_support',
  Dealer = 'dealer',
  RiskManager = 'risk_manager',
  AssetManager = 'asset_manager',
  Vendor = 'vendor',
  Compliance = 'compliance',
  InsideSales = 'inside_sales',
}

export type User = {
  id: string;
  name: string;
  isActive: boolean;
};

export enum DealQualificationStatus {
  None = 'none',
  Valid = 'valid',
  Qualified = 'qualified',
}

export type ScoringModelContextFieldValidationError = {
  name: string;
  message: string;
};

export type QuotaCalculationResult = {
  quotaId: number;
  user: User;
  input: CalculationInput;
  calculationResult: CalculationResult;
  agreement: CalculationAgreement;
  createdDate: string;
  dealer?: string;
  lessee?: string;
  insuranceCompany?: string;
  dealerName?: string;
  lesseeName?: string;
  insuranceCompanyName?: string;
  isLocked: boolean;
  issueId?: number;
  contractNumber?: string;
  contractStatus1C?: string;
  documentUrl?: string;
  contractFolderUrl?: string;
  status?: QuotaStatus;
  statusReason?: string;
  statusComment?: string;
  industryLeasingProductId?: number;
  isContractExecutionNotified: boolean;
  scoringIssueId?: number;
  scoringApprovalIssueId?: number;
  canCreateApproveScoringIssue: boolean;
  hasActiveScoringApproval: boolean;
  dealQualificationStatus: DealQualificationStatus;
  invalidScoringRules?: ScoringModelRuleResultViewModel[];
  corridorErrors?: ScoringModelContextFieldValidationError[];
};

export type QuotaInputHistory = {
  user: User;
  date: string;
};

export type QuotaOwnerHistory = {
  oldOwner: User;
  newOwner: User;
  date: string;
};

export type QuotaHistoryWithAvailableOwners = {
  id: number;
  owner: User;
  availableOwners: User[];
  createdDate: string;
  inputHistory: QuotaInputHistory[];
  ownerHistory: QuotaOwnerHistory[];
};

export type QuotaListViewModel = {
  quotaId: number;
  user: User;
  createdDate: string;
  leaseSubject?: string;
  numberOfItems?: number;
  prepayment?: Amount;
  margin?: number;
  calculationMethod: CalculationMethod;
  numberOfMonths: number;
  fundingAmountNBV: number;
  dealer?: string;
  lessee?: string;
  insuranceCompany?: string;
  currency: Currency;
  issueStatus?: IssueStatus;
  issueId?: number;
  returnReason?: string;
  returnText?: string;
  shipmentIssueStatus?: IssueStatus;
  shipmentIssueId?: number;
  telematicsIssueStatus?: IssueStatus;
  telematicsIssueId?: number;
  contractNumber?: string;
  contractStatus?: string;
  hasComments?: boolean;
  status: QuotaStatus;
  statusReason?: string;
  statusComment?: string;
  verificationIssueId?: number;
  verificationIssueStatus?: IssueStatus;
  nomenclatures: QuotaNomenclatureViewModel[];
  scoringModelType: ScoringModelType;
  scoringModel?: TaskScoringModelResultViewModel;
  dealQualificationStatus: DealQualificationStatus;
};

export type CurrencyViewModel = {
  currency: Currency;
  rate: number;
};

export type CurrencyExchangeRate = {
  from: Currency;
  to: Currency;
  rate: number;
};

export type CurrencyDictionaryViewModel = {
  baseCurrency: Currency;
  currencies: CurrencyViewModel[];
  exchangeRates: CurrencyExchangeRate[];
  updated: string;
};

export enum CounterpartyType {
  dealer = 'dealer',
  lessee = 'lessee',
  insuranceCompany = 'insuranceCompany',
  lessor = 'lessor',
}

export type CounterpartyOption = {
  id?: number;
  inn: string;
  name: string;
  heads?: Head[];
  ratingGrade?: number;
  isDealerVendorSupplier?: boolean;
  isDealerSupportSupplier?: boolean;
};

export type Cof = {
  currency: Currency;
  months: number;
  value: number;
  date: string;
};

export type UserViewModel = {
  id: string;
  name: string;
  isActive?: boolean;
  email?: string;
  isMarginAgreement?: boolean;
};

export type UserShortViewModel = {
  id: string;
  userName: string;
  email: string;
  role?: string;
  roles: string[];
  name: string;
  groups: GroupViewModel[];
  ownedGroups: GroupViewModel[];
  displayName: string;
  activeToDate?: string;
  lastLoginTime?: string;
  isActive: boolean;
  isMarginAgreement: boolean;
};

export enum UserSortBy {
  id = 'id',
  name = 'name',
  email = 'email',
}

export enum GroupSortBy {
  id = 'id',
  name = 'name',
}

export type GroupUserViewModel = {
  id: string;
  userName: string;
  name: string;
};

export type GroupOwnersViewModel = GroupViewModel & {
  owners: GroupUserViewModel[];
};

export type GroupPagedList = PagedList<GroupOwnersViewModel>;

export type GroupUsersViewModel = GroupOwnersViewModel & {
  users: GroupUserViewModel[];
  lessor?: Lessor;
};

export enum QuotaSortBy {
  id = 'id',
  lessee = 'lessee',
}

export type QuotaListFilter = {
  tabId?: number;
  dealerInn?: string[];
  lesseeInn?: string[];
  search?: string;
  ownerId?: string[];
  inn?: string[];
  hasExpectedShipmentDate?: boolean;
  hasShipmentDate?: boolean;
  leasingProduct?: string[];
  status?: QuotaStatus[];
  scoringModelType?: ScoringModelType[];
  page?: number;
  pageSize?: number;
};

export type QuotaListResult = PagedList<QuotaListViewModel>;

export type QuotaSorting = {
  sortBy: QuotaSortBy;
  order: SortOrder;
};

export type QuotaListRequest = QuotaListFilter & Partial<QuotaSorting> & Partial<PageOptions>;

export enum CounterpartySortBy {
  id = 'id',
  inn = 'inn',
  name = 'name',
  missingsCount = 'missingsCount',
  quotasCount = 'quotasCount',
  tier = 'tier',
  isVendorSupplier = 'isVendorSupplier',
  isSupportSupplier = 'isSupportSupplier',
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type Topo = {
  name: string;
  value: string;
};

export type Address = {
  kladrCode: string;
  country?: string;
  zipCode?: string;
  regionCode?: string;
  regionName?: string;
  regionNameFull?: string;
  regionValue?: string;
  settlementName?: string;
  settlementNameFull?: string;
  settlementValue?: string;
  districtName?: string;
  districtNameFull?: string;
  districtValue?: string;
  cityName?: string;
  cityNameFull?: string;
  cityValue?: string;
  streetName?: string;
  streetNameFull?: string;
  streetValue?: string;
  houseName?: string;
  houseNameFull?: string;
  houseValue?: string;
  blockName?: string;
  blockNameFull?: string;
  blockValue?: string;
  flatName?: string;
  flatNameFull?: string;
  flatValue?: string;
  bulkName?: string;
  bulkNameFull?: string;
  bulkValue?: string;
  value?: string;
  comment?: string;
};

export type Head = {
  id: number;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  position: string;
  initials?: string;
  phoneNumber?: string;
  email?: string;
  level?: string;
  reason?: string;
  number?: string;
  date?: string;
};

export type Contact = {
  lastName?: string;
  firstName?: string;
  middleName?: string;
  position: string;
  initials?: string;
  phoneNumber?: string;
  email?: string;
};

export type CounterpartyRequisite = {
  bic: string;
  bank: string;
  account: string;
  correspondentAccount: string;
  isMain: boolean;
};

export type Requisite = {
  bic: string;
  bank: string;
  correspondentAccount: string;
};

export type CounterpartyActivity = {
  code: string;
  text: string;
  date?: string;
};

export type Lessor = {
  inn: string;
  name: string;
};

export type GroupViewModel = {
  id: number;
  name: string;
};

export type UpdateGroupUserViewModel = {
  id: string;
};

export type UserGroupUser = {
  id: string;
  name: string;
  email: string;
};

export type UserGroupViewModel = {
  name: string;
  users: UserGroupUser[];
  owners: UserGroupUser[];
  lessorInn?: string;
};

export type UpdateGroupViewModel = {
  name: string;
  users: UpdateGroupUserViewModel[];
  owners: UpdateGroupUserViewModel[];
  lessorInn: string;
};

export enum CounterpartyStatus {
  Uknown = 'uknown',
  Active = 'active',
  NotActive = 'notActive',
  Liquidation = 'liquidation',
  Bankruptcy = 'bankruptcy',
  Reorganization = 'reorganization',
}

export type CounterpartyViewModel = {
  inn: string;
  name: string;
  fullName: string;
  transliteratedName: string;
  opf?: string;
  opfShort?: string;
  transliteratedOpf?: string;
  ogrn?: string;
  kpp?: string;
  okpo?: string;
  registrationDate?: string;
  isLessee: boolean;
  isDealer: boolean;
  isInsuranceCompany: boolean;
  isLessor: boolean;
  isVerifiedSupplier: boolean;
  isInsuranceAccredited: boolean;
  isVendorSupplier: boolean;
  isSupportSupplier: boolean;
  phoneNumber?: string;
  email?: string;
  legalAddress?: Address;
  actualAddress?: Address;
  mailingAddress?: Address;
  generalCondidionsSellerDate?: string;
  generalCondidionsLesseeDate?: string;
  heads: Head[];
  contacts: Contact[];
  requisites: CounterpartyRequisite[];
  principalActivity?: CounterpartyActivity;
  complementaryActivities: CounterpartyActivity[];
  industry?: IndustryViewModel;
  groups: GroupViewModel[];
  typeOptions: TypeOptions;
  tier: RegionTierValue;
  status: CounterpartyStatus;
  statusText?: string;
  statusUpdatedDate?: string;
  dealerIdentifyStatus?: DealerIdentifyStatus;
  isAlreadyExist: boolean;
  holdingName?: string;
  holdingCode?: string;
  totalRiskDate?: string;
  isDefault: boolean;
};

export type CounterpartyMissingsViewModel = {
  inn: string;
  name: string;
  debtDepth: number;
  overdueAmount: number;
  missingsCount: number;
};

export type CounterpartyListViewModel = {
  id: number;
  inn: string;
  name: string;
  transliteratedName: string;
  isDealer: boolean;
  isLessee: boolean;
  isInsuranceCompany: boolean;
  isLessor: boolean;
  missingsCount: number;
  quotasCount: number;
  tier: RegionTierValue;
  principalActivity?: CounterpartyActivity;
  industryId?: number;
  industryName?: string;
  status: CounterpartyStatus;
  statusText?: string;
  ratingText?: string;
  ratingWarningType: RatingWarningType;
  isSupportSupplier: boolean;
  isVendorSupplier: boolean;
  isVerifiedSupplier: boolean;
};

export enum CounterpartyDealerType {
  IsVendorSupplier = 'isVendorSupplier',
  IsSupportSupplier = 'isSupportSupplier',
}

export type CounterpartyFilter = {
  isDealer?: boolean;
  isLessee?: boolean;
  isInsuranceCompany?: boolean;
  isLessor?: boolean;
  search?: string;
  tier?: RegionTierValue[];
  industryId?: number[];
  activityCode?: string[];
  types?: CounterpartyDealerType[];
};

export type CounterpartySorting = {
  sortBy?: CounterpartySortBy;
  order?: SortOrder;
};

export type TypeOptions = {
  isDealer: boolean;
  isLessee: boolean;
  isInsuranceCompany: boolean;
  isLessor: boolean;
};

export enum DepreciationGroup {
  Group1 = 'group1',
  Group2 = 'group2',
  Group3 = 'group3',
  Group4 = 'group4',
  Group5 = 'group5',
  Group6 = 'group6',
}

export type Depreciation = {
  group: DepreciationGroup;
  numberOfMonths: number;
  coefficient: number;
};

export enum Region {
  RussiaExceptRepublics = 'russiaExceptRepublics',
  RussiaCisEurope = 'russiaCisEurope',
}

export enum PrepaymentAdvanceOrder {
  OneTimeWithFirstPayment = 'oneTimeWithFirstPayment',
  EvenlyDuringFirst12Months = 'evenlyDuringFirst12Months',
  EvenlyDuringLeaseTerm = 'evenlyDuringLeaseTerm',
}

export type ContractCommentViewModel = {
  id: number;
  text: string;
  createdDate: string;
  author?: UserViewModel;
  statusDate?: string;
};

export type ContractViewModel = {
  id: number;
  contractNumber?: string;
  contractType: ContractType;
  date?: string;
  leaseSubject?: string;
  leaseSubjectInDocument?: string;
  depreciation: Depreciation;
  region: Region;
  dealer?: CounterpartyViewModel;
  dealerHead?: Head;
  lessee?: CounterpartyViewModel;
  lesseeHead?: Head;
  lessor?: CounterpartyViewModel;
  lessorHead?: Head;
  prepaymentPercents?: number;
  prepaymentAmount?: number;
  prepaymentAdvanceOrder: PrepaymentAdvanceOrder;
  prepaymentCondition?: string;
  saleCurrency: Currency;
  paymentCurrencyRate?: string;
  paymentPercents?: number;
  paymentAmount?: number;
  paymentCondition?: string;
  expectedShippingDate?: string;
  prefunding: boolean;
  shippingAddress?: string;
  comments?: ContractCommentViewModel[];
  isLocked?: boolean;
  allowedPaymentTypes: PaymentType[];
  canExecuteContract: boolean;
  prepaymentConditionType: ContractPaymentConditionType;
  paymentConditionType: ContractPaymentConditionType;
  prepaymentDayTime: number;
  paymentDayTime: number;
  specificationsOfNomenclatures?: string;
  scoringModelName?: string;
};

export type UpdateContractViewModel = {
  contractNumber?: string;
  contractType: ContractType;
  date?: string;
  leaseSubject?: string;
  leaseSubjectInDocument?: string;
  depreciationGroup: DepreciationGroup;
  region: Region;
  dealerInn?: string;
  dealerHeadId?: number;
  lesseeInn?: string;
  lesseeHeadId?: number;
  lessorInn?: string;
  lessorHeadId?: number;
  prepaymentPercents: number | null;
  prepaymentAmount: number | null;
  prepaymentAdvanceOrder: PrepaymentAdvanceOrder;
  prepaymentCondition?: string;
  saleCurrency: Currency;
  paymentCurrencyRate?: string;
  paymentPercents: number | null;
  paymentAmount: number | null;
  paymentCondition?: string;
  expectedShippingDate?: string;
  prefunding: boolean;
  shippingAddress?: string;
  prepaymentConditionType: ContractPaymentConditionType;
  paymentConditionType: ContractPaymentConditionType;
  prepaymentDayTime: number;
  paymentDayTime: number;
  specificationsOfNomenclatures?: string;
};

export type LeasingProductItemViewModel = {
  id: number;
  name: string;
  brands: string[];
  isVendor: boolean;
};

export type UpdateLeasingProductItemRequest = LeasingProductItemViewModel;

export type SalesPoint = {
  id: number;
  name: string;
};

export type UpdateSalesPointRequest = SalesPoint;

export type TechnicalDevicePassport = {
  number?: number;
  issuedBy?: string;
  issuedDate?: string;
  price?: number;
};

export type ShipmentItemHead = {
  id: number;
  lastName: string;
  firstName?: string;
  middleName?: string;
  reason?: string;
  number?: string;
  date?: string;
};

export type ShipmentItemFileViewModel = {
  downloadUrl: string;
  fileName: string;
  contentType: string;
};

export type ShipmentItemViewModel = {
  id: number;
  quotaId: number;
  expectedShipmentDate?: string;
  shipmentDate?: string;
  brand: string;
  category: string;
  itemModel: string;
  leaseSubject: string;
  leaseSubjectInDocument: string;
  shipmentPlace?: string;
  vin?: string;
  engineNumber?: string;
  chassisNumber?: string;
  bodyNumber?: string;
  color?: string;
  enginePower?: string;
  engineVolume?: string;
  technicalDevicePassport?: TechnicalDevicePassport;
  dealerContact?: ShipmentItemHead;
  lesseeContact?: ShipmentItemHead;
  lessorContact?: ShipmentItemHead;
  isLocked: boolean;
  telematicsIssueId?: number;
  telematicsIssueStatus?: IssueStatus;
  shipmentIssueId?: number;
  shipmentIssueStatus?: IssueStatus;
  shipmentReportIssueId?: number;
  shipmentReportIssueStatus?: IssueStatus;
  file?: ShipmentItemFileViewModel;
  fileUrl?: string;
  canOrderTelematics: boolean;
  canShipItem: boolean;
  nbv?: number;
  margin?: number;
  externalId?: string;
  externalCode?: string;
};

export type ReportShipmentViewModel = {
  shipmentDate: string;
  controls?: IssueControl[];
  description?: string;
};

export type ShipShipmentItemViewModel = {
  expectedShipmentDate?: string;
  shipmentDate?: string;
  brand: string;
  category: string;
  itemModel: string;
  leaseSubject: string;
  leaseSubjectInDocument: string;
  shipmentPlace?: string;
  vin?: string;
  engineNumber?: string;
  chassisNumber?: string;
  bodyNumber?: string;
  color?: string;
  enginePower?: string;
  engineVolume?: string;
  technicalDevicePassport?: TechnicalDevicePassport;
  dealerContact?: ShipmentItemHead;
  lesseeContact?: ShipmentItemHead;
  lessorContact?: ShipmentItemHead;
  file?: File | null;
  fileUrl?: string;
};

export type ShipmentItemDateViewModel = {
  id: number;
  expectedShipmentDate?: string;
};

export type ShipmentViewModel = {
  id: number;
  items: ShipmentItemViewModel[];
  isLocked: boolean;
  lessor?: string;
};

export type UpdateShipmentViewModel = {
  items: ShipmentItemViewModel[];
};

export enum TemplateKind {
  Calculation = 'calculation',
  Contract = 'contract',
  Shipment = 'shipment',
  External1C = 'external1C',
  Pipeline = 'pipeline',
  CalculationDetailed = 'calculationDetailed',
  Quotas = 'quotas',
}

export enum TemplateType {
  Word = 'word',
  Excel = 'excel',
}

export type TemplateDataFilter = {
  tabId?: number;
  users?: string[];
  from?: string;
  to?: string;
  lessees?: string[];
  count?: number;
  isIncludeDelivered: boolean;
  isIncludeLost: boolean;
};

export type TemplateViewModel = {
  id: number;
  kind: TemplateKind;
  title: string;
  description: string;
  users: UserViewModel[];
  downloadUrl: string;
  fileName: string;
  contentType: string;
  documentType: TemplateType;
  disabled: boolean;
  lessor?: CounterpartyOption;
  dealers: CounterpartyOption[];
  isAvailabelForAllUsers: boolean;
};

export type TemplatePagedList = PagedList<TemplateViewModel>;

export type TemplatePrintViewModel = {
  id: number;
  title: string;
  description: string;
  printUrl: string;
  fileName: string;
  documentType: TemplateType;
};

export type TagsDictionary = Record<string, string>;

export type TagMeta = {
  name: string;
  tags: TagsDictionary;
};

export type UserShipmentViewModel = {
  id: string;
  name: string;
  count: number;
  amount: number;
  isCurrent: boolean;
  margin: number;
};

export type UserGroupShipmentViewModel = {
  id: string;
  name: string;
  users: UserShipmentViewModel[];
  totalCount: number;
  totalAmount: number;
  margin: number;
};

export type NewPipelineReportRowItem = {
  id: string;
  name: string;
  isActive: boolean;
  awaitingCount: number;
  awaitingAmount: number;
  readyCount: number;
  readyAmount: number;
  commitedCount: number;
  commitedAmount: number;
  noCommitedCount: number;
  noCommitedAmount: number;
  lostSaleCount: number;
  lostSale: number;
  margin: number;
  readyMargin: number;
  isCurrent: boolean;
};

export type NewPipelineReportGroupItem = {
  name: string;
  users: NewPipelineReportRowItem[];
  totalAwaitingCount: number;
  totalAwaitingAmount: number;
  totalReadyCount: number;
  totalReadyAmount: number;
  totalCommitedCount: number;
  totalCommitedAmount: number;
  totalNoCommitedCount: number;
  totalNoCommitedAmount: number;
  totalLostSaleCount: number;
  totalLostSaleAmount: number;
  margin: number;
};

export type ShipmentItemCounterparty = {
  inn: string;
  name: string;
  transliteratedName: string;
};

export type ShipmentGroupItemViewModel = {
  id: number;
  quotaId: number;
  expectedShipmentDate?: string;
  shipmentDate?: string;
  leaseSubject: string;
  contractNumber: string;
  dealer?: ShipmentItemCounterparty;
  lessee?: ShipmentItemCounterparty;
  lesseeName?: string;
  owner: User;
  expired: boolean;
  issueId?: number;
  issueStatus?: IssueStatus;
  contractStatus?: string;
  fundingAmountNBV: number;
  canUpdateExpectedShipmentDate: boolean;
};

export type ShipmentItemGroup = {
  id: number;
  name: string;
  items: ShipmentGroupItemViewModel[];
};

export type ShipmentEmailNotification = {
  title: string;
  body: string;
};

export type UpdateShipmentDateRequest = {
  itemId: number;
  date: string;
  notification?: ShipmentEmailNotification;
};

export type ShipmentDateRequest = {
  quotaId: number;
  itemId: number;
  date: string;
  notification?: ShipmentEmailNotification;
};

export enum PaymentType {
  AdvancePayment = 'advancePayment',
  Prepayment = 'prepayment',
  Postpayment = 'postpayment',
}

export type OrderPaymentViewModel = {
  paymentType: PaymentType;
  comment?: string;
};

export type PaymentViewModel = OrderPaymentViewModel;

export enum IssueType {
  Error = 'error',
  Contract = 'contract',
  Telematics = 'telematics',
  Shipment = 'shipment',
  Payment = 'payment',
  Rework = 'rework',
  Verification = 'verification',
  ShipmentReport = 'shipmentReport',
  Nomenclature = 'nomenclature',
  Scoring = 'scoring',
  Approval = 'approval',
  MarginApproval = 'marginApproval',
  CounterpartyRatingRisk = 'counterpartyRatingRisk',
  Counterparty = 'counterparty',
}

export enum IssueSource {
  quota = 'quota',
  contract = 'contract',
  shipment = 'shipment',
  home = 'home',
  counterparty = 'counterparty',
  missing = 'missing',
  overdue = 'overdue',
  template = 'template',
  user = 'user',
  dictionary = 'dictionary',
  news = 'news',
  issues = 'issues',
  contracts = 'contracts',
  wiki = 'wiki',
  requests = 'requests',
}

export enum IssueStatus {
  Opened = 'opened',
  InProgress = 'inProgress',
  Postponed = 'postponed',
  ForReview = 'forReview',
  Returned = 'returned',
  Closed = 'closed',
  Verification = 'verification',
  Rework = 'rework',
  InQueue = 'inQueue',
}

export type IssueFile = {
  downloadUrl: string;
  fileName: string;
  contentType: string;
};

export enum ScoringApprovalIssueCommentStatus {
  Requested = 'requested',
  Returned = 'returned',
  Rejected = 'rejected',
  Approved = 'approved',
}

export type IssueCommentViewModel = {
  id: number;
  text: string;
  author: UserViewModel;
  createdDate: string;
  file?: IssueFileViewModel;
  isSystemMessage: boolean;
  creditDecisionStatus?: CreditDecisionStatus;
  scoringApprovalStatus?: ScoringApprovalIssueCommentStatus;
};

export type IssueStatusHistoryViewModel = {
  id: number;
  status: IssueStatus;
  date: string;
  user: UserViewModel;
};

export type IssuePageLink = {
  url: string;
  title: string;
};

export enum IssueFilterTabs {
  None = 'none',
  Mine = 'mine',
  Incoming = 'incoming',
  All = 'all',
}

export type LesseeType = {
  fullName: string;
  inn: string;
  name: string;
};

export type TelematicsViewModel = {
  address: string;
  contactPerson: string;
  installationDate: string;
  contactPhone: string;
  comment?: string;
};

export type IssueFileViewModel = {
  downloadUrl: string;
  fileName: string;
  contentType: string;
  size: number;
};

export enum CreditDecisionStatus {
  No = 'no',
  Yes = 'yes',
  YesBut = 'yesBut',
  YesAutoApproved = 'yesAutoApproved',
}

export type ApprovalViewModel = {
  status: CreditDecisionStatus;
  description?: string;
  file?: IssueFileViewModel;
  createdDate: string;
};

export type MarginApprovalViewModel = {
  margin: number;
};

export type QuotaMarginInputModel = MarginApprovalViewModel;

export type IssueViewModel = {
  id: number;
  title: string;
  description?: string;
  lessee?: LesseeType;
  dealer?: LesseeType;
  documentUrl: string;
  issueType: IssueType;
  issueSource: IssueSource;
  author: UserViewModel;
  assignee?: UserViewModel;
  modifier?: UserViewModel;
  createdDate: string;
  updatedDate?: string;
  status: IssueStatus;
  file?: IssueFile;
  quotaId?: number;
  shipmentItemId?: number;
  comments?: IssueCommentViewModel[];
  statusHistory?: IssueStatusHistoryViewModel[];
  link: IssuePageLink;
  returnReason?: string;
  returnText?: string;
  contractNumber?: string;
  contractFolderUrl?: string;
  contractStatus?: string;
  telematics?: TelematicsViewModel;
  payment?: PaymentViewModel;
  approval?: ApprovalViewModel;
  timeInLastStatus?: string;
  timeInProgressStatus?: string;
  canBeTaken: boolean;
  canBeClosed: boolean;
  canBeVerificated: boolean;
  canBeSendTo1C: boolean;
  canBeOpenedAgain: boolean;
  canBeClosedAgain: boolean;
  canBeReturned: boolean;
  controls?: IssueControl[];
  shipmentDate?: string;
  nomenclature?: NomenclatureIssueViewModel;
  scoringApprovalComments?: IssueCommentViewModel[];
  marginApproval?: MarginApprovalViewModel;
  isActive?: boolean;
  ratingRisk?: RatingRiskViewModel;
  counterparty?: CounterpartyDataViewModel;
};

export type CloseScoringApprovalIssueViewModel = {
  status: CreditDecisionStatus;
  description?: string;
  file: File | null;
};

export type ReturnScoringApprovalIssueViewModel = {
  description: string;
  file: File | null;
};

export type OpenAgainScoringApprovalIssueViewModel = {
  description: string;
  file: File;
};

export type CancelScoringApprovalIssueViewModel = {
  description: string;
  file: File | null;
};

export type CreateScoringApprovalIssueViewModel = {
  description: string;
  file: File;
  documentUrl: string;
};

export type ScoringModelRouteViewModel = {
  id: number;
};

export type UpdateIssueStatusModel = {
  status: IssueStatus;
  comment?: string;
  issueType?: IssueType;
};

export type AddIssueCommentModel = {
  comment?: string;
  file?: IssueFile;
};

export type IssueFilter = {
  statuses?: IssueStatus[];
  types?: IssueType[];
  assigneeId?: string[];
  authorId?: string[];
  search?: string;
  quotaIds?: number[];
  hideCompleted?: boolean;
  fromDate?: string;
  toDate?: string;
};

export type ReturnIssueModel = {
  returnReason: string;
  returnText?: string;
};

export type OpenAgainIssueModel = {};

export type TaskViewModel = {
  id: number;
  title: string;
  description: string;
  author: User;
  status: IssueStatus;
  assignee: User;
  issueType: IssueType;
  issueSource: IssueSource;
  createdDate: string;
  timeInLastStatus?: string;
  timeInProgressStatus?: string;
  timeInOpenedStatus?: string;
  updatedDate?: string;
  canBeTaken: boolean;
  canBeClosed: boolean;
  canBeVerificated: boolean;
  canBeSendTo1C: boolean;
  canBeOpenedAgain: boolean;
  canBeReturned: boolean;
  fundingAmountNBV?: number;
  dealQualificationStatus?: DealQualificationStatus;
};

export type TaskScoringModelResultViewModel = {
  id: number;
  name: string;
  isValid: boolean;
};

export type TaskWithQuotaViewModel = TaskViewModel & {
  quotaId: number;
  contractStatus: string;
  contractNumber: string;
  lesseeName: string;
  lesseeInn: string;
  scoringModel?: TaskScoringModelResultViewModel;
};

export type IssuePagedList = PagedList<TaskViewModel>;

export type IssueGroupedPagedList = PagedList<TaskWithQuotaViewModel>;

export type QuotaIssueViewModel = {
  quotaId: number;
  contractNumber: string;
  status: string;
  time: string;
  issues: IssueViewModel[];
};

export type CreateIssueRequest = {
  issueType: IssueType;
  issueSource: IssueSource;
  title: string;
  description: string;
  quotaId?: number;
  link: IssuePageLink;
  assigneeId?: string;
};

export type NewsItemViewModel = {
  category: 'critical' | 'general';
  from: string;
  isViewed: boolean;
  section: string[];
  // | '0'
  // | '1'
  // | '2'
  // | '4'
  // | '8'
  // | '16'
  // | '32'
  // | '64'
  // | '128';
  text: string;
  title: string;
  to: string;
  createdDate: string;
  id: number;
  color?: string;
};

export const NewsItemSection = {
  home: '1',
  users: '2',
  quotas: '4',
  counterparties: '8',
  news: '16',
  templates: '32',
  issues: '64',
  dictionaries: '128',
  missing: '256',
  wiki: '512',
  contracts: '1024',
};

export type NewsData = {
  data: NewsItemViewModel[];
  page: number;
  pageCount: number;
  totalCount: number;
};

export enum ContractType {
  Lease = 'lease',
  Loan = 'loan',
  LeaseNoVAT = 'leaseNoVAT',
}

export type CounterpartyContractViewModel = {
  inn: string;
  fullName: string;
  head?: Head;
  requisite: CounterpartyRequisite;
};

export type ContractToExecutionViewModel = {
  prepaymentPercents: number;
  prepaymentAmount?: number;
  prepaymentAdvanceOrder: PrepaymentAdvanceOrder;
  prepaymentCondition?: string;
  paymentCurrencyRate: string;
  paymentPercents: number;
  paymentAmount?: number;
  paymentCondition?: string;
  expectedShippingDate?: string;
  prefunding: boolean;
  shippingAddress?: string;
  prepaymentConditionType?: ContractPaymentConditionType;
  paymentConditionType?: ContractPaymentConditionType;
  prepaymentDayTime: number;
  paymentDayTime: number;
  specificationsOfNomenclatures?: string;
};

export type SendQuotaToExecutionViewModel = {
  documentUrl: string;
  link: {
    url: string;
    title: string;
  };
  title: string;
  description: string;
  contractType: ContractType;
  lessee: CounterpartyContractViewModel;
  dealer?: CounterpartyContractViewModel;
  contract?: ContractToExecutionViewModel;
};

export type MissingFilter = {
  inn?: string[];
  contractNumber?: string;
  documentType?: string[];
  status?: string[];
  debt?: boolean;
  managerId?: string[];
  tabId?: number;
  debtDays?: number;
};

export enum MissingSortBy {
  PlannedDeliveryDate = 'plannedDeliveryDate',
  DebtDepth = 'debtDepth',
  DeliveryStatus = 'deliveryStatus',
}

export type MissingUser = User & {
  email: string;
};

export enum MissingCommentDeliveryStatus {
  delivered = 'delivered',
  notFound = 'notFound',
}

export type MissingCommentViewModel = {
  id: number;
  text: string;
  author?: MissingUser;
  statusDate?: string;
  plannedDeliveryDate?: string;
  createdDate: string;
  analytics?: string;
  deliveryStatus?: MissingCommentDeliveryStatus;
};

export enum MissingStatus {
  NotCommented = 'notCommented',
  Commented = 'commented',
  Received = 'received',
}

export enum MissingDebtStatus {
  None = 'none',
  Ok = 'ok',
  DaysCalculation = 'daysCalculation',
}

export enum MissingOverdueStatus {
  None = 'none',
  Ok = 'ok',
  Warning = 'warning',
}

export enum CommentStatus {
  NoComments = 'noComments',
  HasComments = 'hasComments',
}

export type MissingViewModel = {
  id: number;
  externalId?: string;
  contractNumber?: string;
  lesseeInn?: string;
  lesseeName?: string;
  dealerInn?: string;
  dealerName?: string;
  managerName?: string;
  salesPoint?: string;
  documentType?: string;
  status?: string;
  statusDate?: string;
  plannedDeliveryDate?: string;
  createdDate?: string;
  updatedDate?: string;
  manager?: UserViewModel;
  missingStatus: MissingStatus;
  missingDebtStatus: MissingDebtStatus;
  comments: MissingCommentViewModel[];
  debtDays: number;
  debtDepth?: number;
  overdueAmount?: number;
  dpdCounter?: number;
  penaltyCharge?: number;
  overdueStatus: MissingOverdueStatus;
  analytics?: string;
  canBeDelivered: boolean;
  canBeNotFound: boolean;
  hasDeliveredStatus: boolean;
  commentStatus?: CommentStatus;
  contractStatus?: string;
  currency: string;
};

export type MissingOverdueCommentViewModel = {
  id: number;
  contractNumber: string;
  contractStatus: string;
  updatedDate: string;
  text?: string;
  createdDate: string;
  currency: string;
  debtDepth?: number;
  overdueAmount?: number;
  penaltyCharge?: number;
  author?: MissingUser;
};

export type MissingOverdueViewModel = {
  contractNumber: string;
  contractStatus: string;
  updatedDate: string;
  comments: MissingOverdueCommentViewModel[];
  debtDays: number;
  debtDepth?: number;
  overdueAmount?: number;
  dpdCounter?: number;
  penaltyCharge?: number;
  overdueStatus: MissingOverdueStatus;
  currency: string;
  commentStatus?: CommentStatus;
};

export type MissingPagedList = PagedList<MissingViewModel>;

export type MissingCounterpartyViewModel = {
  inn: string;
  name: string;
};

export type MissingManagerViewModel = {
  id: string;
  name: string;
};

export type ListRequestBase = {
  search?: string;
};

export type PageOptions = {
  page?: number;
  pageSize?: number;
};

export type MissingContractListRequest = ListRequestBase & {
  count?: number;
};

export type MissingDocumentTypeListRequest = ListRequestBase;

export type MissingLesseeListRequest = ListRequestBase & {
  count?: number;
};

export type MissingDealerListRequest = ListRequestBase & {
  count?: number;
};

export type MissingManagerListRequest = ListRequestBase & {
  tabId?: number;
  count?: number;
};

export type MissingStatusListRequest = ListRequestBase;

export type MissingSorting = {
  sortBy?: MissingSortBy;
  order?: SortOrder;
};

export type MissingListRequest = MissingFilter & MissingSorting & PageOptions;

export type CommentMissingOverdueViewModel = {
  text: string;
};

export type MissinHistoryDateGroup = {
  date: string;
  count: number;
};

export type MissingHistoryCountGroup = {
  min: number;
  max?: number;
  dates: MissinHistoryDateGroup[];
};

export type MissingHistoryTimeGroups = {
  group31: MissingHistoryCountGroup;
  group91: MissingHistoryCountGroup;
  group361: MissingHistoryCountGroup;
  total: MissingHistoryCountGroup;
};

export enum MissingHistoryTime {
  Weeks = 'weeks',
  Months = 'months',
}

export type MissingHistoryRequest = {
  time: MissingHistoryTime;
  documentType: string[];
  managerId?: string[];
};

export type MissingHistoryCountLabel = {
  text: string;
  count: number;
  source?: string;
  inn?: string;
  email?: string;
  isActive: boolean;
};

export enum MissingHistoryCountGroupTabId {
  Counterparties = 'counterparties',
  Documents = 'documents',
  Managers = 'managers',
}

export type MissingHistoryCountGroupTab = {
  id: MissingHistoryCountGroupTabId;
  data: MissingHistoryCountLabel[];
};

export type MissingHistoryTab = {
  id: number;
  name: string;
  timeGroups: MissingHistoryTimeGroups;
  countGroups: MissingHistoryCountGroupTab[];
};

export type MissingImportDates = {
  missings?: string;
  overdues?: string;
};

export type UpdateDealerCommand = {
  role?: string;
  lastName?: string;
  middleName?: string;
  firstName?: string;
  phoneNumber?: string;
  leasingPrograms: string[];
  salesPoints: string[];
  email?: string;
  isActive?: boolean;
  activeToDate?: string;
};

export type MissingOverdueTop = {
  contractNumber: string;
  lesseeInn: string;
  lesseeName: string;
  managerEmail: string;
  managerName: string;
  managerId: string;
  isActive: boolean;
  overdueStatus: MissingOverdueStatus;
};

export type MissingOverdueTopDebtDepth = MissingOverdueTop & {
  debtDepth: number;
};

export type MissingOverdueTopAmount = MissingOverdueTop & {
  overdueAmount: number;
  currency: string;
};

export type MissingOverdueTab = {
  id: number;
  name: string;
  debts: MissingOverdueTopDebtDepth[];
  amounts: MissingOverdueTopAmount[];
};

export enum MissingOverdueSortBy {
  DebtDepth = 'DebtDepth',
  OverdueAmount = 'overdueAmount',
  MissingCount = 'missingCount',
  Exposure = 'exposure',
}

export type MissingOverdueSorting = {
  sortBy: MissingOverdueSortBy;
  order: SortOrder;
};

export type MissingOverdueFilter = {
  inn?: string[];
  contractNumber?: string;
  contractStatus?: string[];
  tabId?: number;
  managerEmail?: string[];
};

export type MissingOverdueListModel = {
  id: number;
  contractNumber: string;
  updatedDate: string;
  currency: string;
  debtDepth?: number;
  overdueAmount?: number;
  dpdCounter: number;
  missingCount: number;
  penaltyCharge?: number;
  overdueStatus: MissingOverdueStatus;
  lesseeInn: string;
  lesseeName: string;
  dealerInn: string;
  dealerName: string;
  contractStatus: string;
  managerName: string;
  managerEmail: string;
  managerId: string;
  isActive: boolean;
  commentStatus: CommentStatus;
  exposure?: number;
  isManagerActive: boolean;
};

export type MissingOverduePagedList = PagedList<MissingOverdueListModel>;

export type MissingOverdueListRequest = MissingOverdueFilter & MissingOverdueSorting & PageOptions;

export type ContractListFilter = {
  contractNumber?: string;
  dealerInn?: string[];
  lesseeInn?: string[];
  group?: string;
  groupName?: string;
  owned?: boolean;
  tabId?: number;
  status?: string[];
};

export type CounterpartyShortViewModel = {
  inn: string;
  name: string;
  fullName: string;
};

export type ContractListViewModel = {
  user: UserViewModel;
  assignee?: UserViewModel;
  contractNumber: string;
  date: string;
  lessee?: CounterpartyShortViewModel;
  dealer?: CounterpartyShortViewModel;
  status?: string;
  taskId?: number;
  quotaId?: number;
  contractStatus: ContractStatus;
};

export type ContractPagedList = PagedList<ContractListViewModel>;

export enum ContractSortBy {
  ContractNumber = 'contractNumber',
  Date = 'date',
  Lessee = 'lessee',
  Dealer = 'dealer',
  Status = 'status',
}

export type ContractSorting = {
  sortBy: ContractSortBy;
  order: SortOrder;
};

export enum ContractStatus {
  NotCommented = 'notCommented',
  Commented = 'commented',
  Received = 'received',
}

export type ContractListRequest = ContractListFilter & PageOptions & ContractSorting;

export type ContractNumberRequest = {
  search?: string;
  count?: number;
};

export type ContractStatusesRequest = {
  search?: string;
  count?: number;
};

export type ContractDealersRequest = {
  owned?: boolean;
  ownerId?: string;
  group?: string;
  search?: string;
  count?: number;
  tabId?: number;
};

export type ContractLesseesRequest = ContractDealersRequest;

export type ContractLesseeViewModel = {
  lesseeInn: string;
  name: string;
};

export type ContractDealerViewModel = {
  dealerInn: string;
  name: string;
};

export type AddContractCommentModel = {
  text: string;
};

export type OrderTelematicsViewModel = TelematicsViewModel & {
  link: {
    url: string;
    title: string;
  };
};

export type RatingInput = {
  totalAssets: number;
  netIntangibleAssets: number;
  intragroupLoansOlderOneYear: number;
  intragroupLoansNewerOneYear: number;
  intragroupDZ: number;
  accountCashierAndMoney: number;
  totalCashAndInvestments: number;
  capitalAndReserves: number;
  longTermDuties: number;
  otherLongTermDuties: number;
  shortTermBorrowedFunds: number;
  otherShortTermBorrowedFunds: number;
  partOfSubordinatedDebtPayableIn12Months: number;
  cashInParticipantsAccounts: number;
  leasingObligations: number;
  operationalLeasing: number;
  revenue: number;
  salesProfit: number;
  operatingResultOfActivitiesToBeDiscontinued: number;
  profitBeforeTax: number;
  amortization: number;
  expensesOnOffBalance: number;
  answers: RatingAnswer[];
  sustainableEBITDA: number;
  isEmptyValues?: number;
  clearIncome: number;
};

export type RatingInputViewModel = RatingInput & {
  fromDate?: string;
  toDate?: string;
  validFromDate?: string;
  validToDate?: string;
};

export type RatingOutput = {
  totalScore: number;
  intermediatePD: number;
  intermediateRating: number;
  calculatedPD: number;
  finalPD: number;
  cdrImpact: number;
  ratingGrade: number;
  isManual: boolean;
  ageCorrection: number;
  manualCorrection: number;
  ratingOriginal: number;
};

export enum RatingStatus {
  Calculated = 'calculated',
  Confirmed = 'confirmed',
  Expired = 'expired',
}

export type RatingCommentViewModel = {
  id: number;
  text: string;
  date: string;
  author: UserViewModel;
};

export type RatingViewModel = RatingCalculationViewModel & {
  id: number;
  inn: string;
  createdDate: string;
  updatedDate: string;
  user: UserViewModel;
  fromDate?: string;
  toDate?: string;
  isConfirmed: boolean;
  comments: RatingCommentViewModel;
  status: RatingStatus;
  validFromDate?: string;
  validToDate?: string;
  isAutomatic?: boolean;
  year?: number;
};

export type ConfirmRatingViewModel = {
  validFrom: string;
  validTo: string;
  grade: number;
};

export type RatingCalculationContext = {
  factorScores: number[];
  factorWeights: number[];
  weightedFactorScores: number[];
  finalRatios: number[];
  adjustedRatioScores: number[];
  ratioWeights: number[];
  weightedRatioScores: number[];
  totalQual: number;
  totalQuan: number;
  totalScore: number;
  intermediatePD: number;
  intermediateRating: number;
  calculatedPD: number;
  cdrImpact: number;
  ratingGrade: number;
};

export type RatingCalculationViewModel = {
  input: RatingInput;
  output: RatingOutput;
  calculationContext: RatingCalculationContext;
};

export enum RatingQuestionType {
  Question1 = 'question1',
  Question2 = 'question2',
  Question3 = 'question3',
  Question4 = 'question4',
  Question5 = 'question5',
  Question6 = 'question6',
  Question7 = 'question7',
  Question8 = 'question8',
  Question9 = 'question9',
  Question10 = 'question10',
  Question11 = 'question11',
  Question12 = 'question12',
  Question13 = 'question13',
  Question14 = 'question14',
  Question15 = 'question15',
  Question16 = 'question16',
  Question17 = 'question17',
  Question18 = 'question18',
}

const businessQuestions = [
  RatingQuestionType.Question1,
  RatingQuestionType.Question2,
  RatingQuestionType.Question3,
  RatingQuestionType.Question4,
  RatingQuestionType.Question5,
  RatingQuestionType.Question6,
];

export const getBussinessAnswers = (input: RatingInput) =>
  input.answers.filter((t) => businessQuestions.indexOf(t.questionId) > -1);

export enum RatingSortBy {
  FromDate = 'fromDate',
}

export type RatingAnswer = {
  questionId: RatingQuestionType;
  value: number;
  factor?: number;
  score?: number;
  weight?: number;
};

export enum RatingAnswer1 {
  High,
  Medium,
  Low,
}

export enum RatingAnswer2 {
  Positively,
  Neutrally,
  Negatively,
}

export enum RatingAnswer3 {
  OutperformsCompetition,
  RoughtlyInLineWithCompetition,
  DontKnow,
  OftenBelowCompetition,
}

export enum RatingAnswer4 {
  Stable,
  SlightlyVolatile,
  ModerateVolatile,
  HighlyVolatile,
}

export enum RatingAnswer5 {
  VeryGood,
  Good,
  Average,
  BelowAverage,
  Poor,
  DontKnow,
}

export enum RatingAnswer6 {
  NonePresent,
  ContinuosFullUsage,
  OtherFinancialInstitutions,
  RequestForDelayInPayment,
  UnexpectedWorsening,
  OthersNamelyHigh,
  ExternalSignals,
  FrequentChangesInAuditors,
  PandingMaterialRegulatryInvestigations,
  RequestForCovenantAmendment,
  SuddenChangeInManagement,
  FinancialStatementsReceivedLate,
  OthersNamelyAverage,
}

export enum RatingAnswer7 {
  Effectively,
  ReasonablyEffectively,
  NotEffectively,
  NotApplicable,
  DontKnow,
}

export enum RatingAnswer8 {
  SignificantlyAbovePlan,
  SlightlyAbovePlan,
  InLineWithPlan,
  SlightlyBelowPlan,
  SignificantlyBelowPlan,
  DontKnow,
  DoesntApplyNoPlanRequested,
}

export enum RatingAnswer9 {
  Yes,
  LimitedAccess,
  No,
}

export enum RatingAnswer10 {
  Yes,
  No,
}

export enum RatingAnswer11 {
  Growing,
  MaturedOrStable,
  Consolidating,
  Declining,
}

export enum RatingAnswer12 {
  Years5More,
  Years3To5,
  Years1To3,
  Years1Less,
}

export enum RatingAnswer13 {
  Good,
  AboveAverage,
  Average,
  BelowAverage,
  Poor,
  DontKnow,
}

export enum RatingAnswer14 {
  Good,
  AboveAverage,
  Average,
  BelowAverage,
  Poor,
  DontKnow,
}

export enum RatingAnswer15 {
  No,
  Yes,
  Moderately,
  DontKnow,
}

export enum RatingAnswer16 {
  LocalPlayer,
  SomeInternationalOperations,
  ManyInternationalOperations,
  PrimaryInternationalOperations,
  DontKnow,
}

export enum RatingAnswer17 {
  Agriculture,
  BusinessProducts,
  ComunicationsOrHiTech,
  Construction,
  ConsumerProducts,
  MiningTransportationUtilities,
  Services,
  Trade,
}

export enum RatingAnswer18 {
  Years10,
  Years5To10,
  Years2To5,
  Years0To2,
  DontKnow,
}

export type IssueProgressReportRequest = {
  isInProgress: boolean;
  count?: number;
  from?: string;
  to?: string;
};

export type AssigneeReportCount = {
  total: number;
  isLongProgress: number;
};

export type AssigneeReportViewModel = {
  id: string;
  name: string;
  isActive: boolean;
  contractsCount: AssigneeReportCount;
  paymentsCount: AssigneeReportCount;
  telematicsCount: AssigneeReportCount;
  shipmentsCount: AssigneeReportCount;
  verificationsCount: AssigneeReportCount;
};

export type NotAssignedIssuesViewModel = {
  contracts: number[];
  payments: number[];
  telematics: number[];
  shipments: number[];
  verifications: number[];
};

export type AddQuotaNoteViewModel = {
  text: string;
};

export type QuotaNoteViewModel = {
  text: string;
  createdDate: string;
  author: UserViewModel;
  status?: QuotaStatus;
  statusComment?: string;
};

export type ApplicationUserViewModel = {
  id: string;
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber: string;
  dalerInn: string;
  roles: string[];
  ownedGroups: number[];
  memberedGroups: number[];
  leasingPrograms: string[];
  salesPoints: number[];
};

export type ApplicationUsersTab = {
  id: number;
  name: string;
  users: ApplicationUserViewModel[];
  userIds: string[];
  userEmails: string[];
};

export enum ApplicationUserTab {
  mine = 0,
  all = 2147483647,
}

export enum ExternalQuotaTab {
  Mine = 'mine',
  Incoming = 'incoming',
  All = 'all',
}

export type RatingYear = {
  year: number;
  input: RatingInput;
};

export type WikiFilter = {
  tags?: string[];
  search?: string;
};

export enum WikiSortBy {
  Id = 'Id',
}

export type WikiSorting = {
  sortBy: WikiSortBy;
  order: SortOrder;
};

export type WikiItemListViewModel = {
  id: number;
  title: string;
  text: string;
  author: UserViewModel;
  createdDate: string;
};

export type WikiPagedList = PagedList<WikiItemListViewModel>;

export type WikiItemCommentViewModel = {
  id: number;
  text: string;
  author: UserViewModel;
  createdDate: string;
  files: WikiFileViewModel[];
};

export type WikiFileViewModel = {
  id: number;
  downloadUrl: string;
  fileName: string;
  contentType: string;
};

export type WikiItemViewModel = WikiItemListViewModel & {
  comments: WikiItemCommentViewModel[];
  files: WikiFileViewModel[];
  tags: string[];
};

export type AddWikiItemRequest = {
  title: string;
  text: string;
  files: File[];
  tags: string[];
};

export type WikiAttachment = {
  id: number;
  fileName: string;
};

export type UpdateWikiItemViewModel = AddWikiItemRequest & {
  attachments: string[];
};

export type WikiTagViewModel = {
  id: number;
  text: string;
};

export type WikiTagPagedList = PagedList<WikiTagViewModel>;

export type WikiTagFilter = {
  search?: string;
};

export enum WikiTagSortBy {
  Text = 'Text',
}

export type WikiTagSorting = {
  sortBy: WikiTagSortBy;
  order: SortOrder;
};

export type WikiTagListRequest = PageOptions &
  WikiTagSorting & {
    search?: string;
  };

export type CommentWikiViewModel = {
  id: number;
  text: string;
  author: UserViewModel;
  createdDate: string;
  files?: any;
};

export type AddWikiTagRequest = {
  text: string;
};

export type UpdateWikiTagViewModel = {
  text: string;
};

export type ShipmentReportRequest = {
  start?: string;
  end?: string;
  tabId?: number;
  salesChannel?: string;
};

export type NewPipelineReportRequest = {
  start?: string;
  end?: string;
  tabId?: number;
  salesChannel?: string;
  isAddAll?: boolean;
};

export type ShipmentItemsReportRequest = ShipmentReportRequest & {
  expiredOnly?: boolean;
};

export type TvDashboardItem = {
  count: number;
  nbvAmount: number;
};

export type TvDashboardViewModel = {
  quotasCount: number;
  contractsToday: TvDashboardItem;
  shipmentsToday: TvDashboardItem;
  shipmentsNext7Days: TvDashboardItem;
  shipmentsToEndOfMonthDays: TvDashboardItem;
  confirmedShipmentsToEndOfMonthDays: TvDashboardItem;
};

export enum RegionTierValue {
  tier1 = 'tier1',
  tier2 = 'tier2',
  tier3 = 'tier3',
}

export enum RegionTierSortBy {
  name = 'name',
  code = 'code',
  tier = 'tier',
}

export type RegionTierSorting = {
  sortBy: RegionTierSortBy;
  order: SortOrder;
};

export type RegionTierFilter = {
  search?: string;
  tier?: RegionTierValue;
};

export type RegionTierViewModel = {
  code: string;
  name: string;
  tier: RegionTierValue;
};

export type RegionTierPagedList = PagedList<RegionTierViewModel>;

export type AddRegionTierRequest = {
  code: string;
  name: string;
  tier: RegionTierValue;
};

export type UpdateRegionTierViewModel = {
  name: string;
  tier: RegionTierValue;
};

export type RegionViewModel = {
  code: string;
  name: string;
};

export enum QuotaStatus {
  Active = 'active',
  Lost = 'lost',
  Archived = 'archived',
}

export type SetQuotaStatusRequest = {
  quotaId: number[];
  status: QuotaStatus;
  reason: string;
  comment?: string;
};

export type QuotaLostReason = {
  id: number;
  text: string;
};

export type QuotaLostReasonPagedList = PagedList<QuotaLostReason>;

export enum QuotaLostReasonSortBy {
  text = 'text',
}

export type QuotaLostReasonSorting = {
  sortBy: QuotaLostReasonSortBy;
  order: SortOrder;
};

export type QuotaLostReasonFilter = {
  search?: string;
};

export type AddQuotaLostReasonRequest = {
  text: string;
};

export type UpdateQuotaLostReasonViewModel = AddQuotaLostReasonRequest;

export type IssueControl = {
  id: number;
  issueType: IssueType;
  text: string;
  sortOrderNumber: number;
};

export type IssueControlPagedList = PagedList<IssueControl>;

export enum IssueControlSortBy {
  text = 'text',
  issueType = 'issueType',
  sortOrderNumber = 'sortOrderNumber',
}

export type IssueControlSorting = {
  sortBy: IssueControlSortBy;
  order: SortOrder;
};

export type IssueControlFilter = {
  search?: string;
  types?: IssueType[];
};

export type AddIssueControlRequest = {
  issueType: IssueType;
  text: string;
  sortOrderNumber: number;
};

export type UpdateIssueControlViewModel = AddIssueControlRequest;

export type UpdateControlViewModel = {
  isChecked: boolean;
};

export type ShipmentReportViewModel = {
  quotaId: number;
  contractNumber?: string;
  contractStatus?: string;
  lessee?: CounterpartyMissingsViewModel;
  dealer?: CounterpartyMissingsViewModel;
};

export type NomenclatureViewModel = {
  id: number;
  code: string;
  date?: string;
  model: string;
  vendor: string;
  leaseSubject?: string;
  depreciationGroup?: string;
  category?: string;
  categoryCode?: string;
  equipment?: string;
  assetType?: string;
  liquidityClass?: string;
  isDeleted: boolean;
  isTelematicsEnabled?: boolean;
  name: string;
};

export type NomenclaturePagedList = PagedList<NomenclatureViewModel>;

export enum NomenclatureSortBy {
  model = 'model',
  vendor = 'vendor',
  category = 'category',
  categoryCode = 'categoryCode',
  leaseSubject = 'leaseSubject',
  code = 'code',
  depreciationGroup = 'depreciationGroup',
  equipment = 'equipment',
  assetType = 'assetType',
  liquidityClass = 'liquidityClass',
}

export type NomenclatureFilter = {
  search?: string;
  category?: string;
  categoryCode?: string;
  vendor?: string;
  model?: string;
  leaseSubject?: string;
  depreciationGroup?: string;
  equipment?: string;
  assetType?: string;
  code?: string;
  onlyConfirmed?: boolean;
};

export type NomenclatureSorting = {
  sortBy: NomenclatureSortBy;
  order: SortOrder;
};

export type ShipmentListRequestFilter = {
  from?: string;
  to?: string;
};

export enum ShipmentListRequestSortBy {
  Id = 'id',
  UserId = 'userId',
  ContractNumber = 'contractNumber',
  ExpectedShipmentDate = 'expectedShipmentDate',
  ShipmentDate = 'shipmentDate',
  Code = 'code',
  Nbv = 'nbv',
  Margin = 'margin',
  SerialNumber = 'serialNumber',
  SerialNumberCode = 'serialNumberCode',
}

export type ShipmentListRequestSorting = {
  sortBy: ShipmentListRequestSortBy;
  order: SortOrder;
};

export type ShipmentListItemViewModel = {
  id: number;
  userId: string;
  userName: string;
  contractNumber: string;
  expectedShipmentDate?: string;
  shipmentDate?: string;
  code?: string;
  nbv?: number;
  margin?: number;
  serialNumber?: string;
  serialNumberCode?: string;
};

export type ShipmentPagedList = PagedList<ShipmentListItemViewModel>;

export type QuotaNomenclatureViewModel = {
  id?: number;
  code?: string;
  price?: number;
  name: string;
  count: number;
  year?: number;
  isSecondHand: boolean;
  taskId?: number;
  isTelematicsEnabled?: boolean;
};

export type AddNomenclatureIssueViewModel = {
  id: number;
};

export type AddNomenclatureIssuesViewModel = {
  nomenclatures: AddNomenclatureIssueViewModel[];
};

export type CloseNomenclatureIssueViewModel = {
  code: string;
};

export type NomenclatureIssueViewModel = {
  name: string;
  code?: string;
  model?: string;
  vendor?: string;
  leaseSubject?: string;
};

export type CalculationInputSettings = {
  margin: number;
  minMargin: number;
  maxMargin: number;
  cof: number;
  cofAdd: number;
  tradeFee: number;
  vat: number;
};

export type CalcMarginSettingViewModel = {
  baseMarginSetting: BaseMarginSettingViewModel;
  retailMarginSetting: RetailMarginSettingViewModel;
  boxMarginSetting: RetailMarginSettingViewModel;
  ratingBonusSetting: RatingBonusSettingViewModel[];
};

export type BaseMarginSettingViewModel = {
  operatingExpenses: number;
  riskPremiumCalculationCoef: number;
  minimumIncome: number;
  borderFrom: number;
  borderTo: number;
};

export type RetailMarginSettingViewModel = {
  riskPremiumCalculationCoef: number;
  minimumIncome: number;
  borderFrom: number;
  borderTo: number;
};

export type RatingBonusSettingViewModel = {
  name: string;
  ratingIndex: number;
  pd: number;
  risk: number;
};

export type TopDealerViewModel = {
  inn: string;
  name: string;
};

export type TopDealerQuotaCountViewModel = {
  dealer: TopDealerViewModel;
  count: number;
};

type NomenclatureScheduleItem = {
  monthNumber: number;
  value: number;
};

export type NomenclatureScheduleViewModel = {
  code: string;
  date: string;
  category?: string;
  categoryCode?: string;
  items: NomenclatureScheduleItem[];
};

export type NomenclatureCategoryScheduleFilter = {
  code?: string;
  categoryName?: string;
  categoryCode?: string;
};

export enum NomenclatureCategoryScheduleSortBy {
  Code = 'code',
  Date = 'date',
  CategoryName = 'categoryName',
  CategoryCode = 'categoryCode',
}

export type NomenclatureCategorySchedulePagedList = PagedList<NomenclatureScheduleViewModel>;

export type NomenclatureCategoryScheduleSorting = {
  sortBy: NomenclatureCategoryScheduleSortBy;
  order: SortOrder;
};

export type IndustrySpecializationViewModel = {
  id: number;
  industryId: number;
  industryName: string;
  code: string;
  description: string;
  isLicensed: boolean;
  isFinanced: boolean;
  isHighRisk: boolean;
  debt?: number;
  debtDate?: string;
};

export type IndustrySpecializationListItemViewModel = IndustrySpecializationViewModel & {
  count: number;
};

export type IndustryViewModel = {
  id: number;
  name: string;
  parameterEstimation: boolean;
  raks: boolean;
  answer1?: RatingAnswer1;
  answer2?: RatingAnswer2;
  answer3?: RatingAnswer3;
  answer4?: RatingAnswer4;
  answer5?: RatingAnswer5;
  answer6?: RatingAnswer6;
  counterpartiesCount?: number;
};

export type IndustrySpecializationPagedList = PagedList<IndustrySpecializationListItemViewModel> & {
  debtDate?: string;
};

export type IndustryPagedList = PagedList<IndustryViewModel>;

export type IndustrySorting = {
  sortBy: IndustrySortBy;
  order: SortOrder;
};

export type IndustrySpecializationSorting = {
  sortBy: IndustrySpecializationSortBy;
  order: SortOrder;
};

export enum IndustrySpecializationSortBy {
  Id = 'id',
  IndustryName = 'industryName',
  Code = 'code',
  Description = 'description',
  IsLicensed = 'isLicensed',
  IsFinanced = 'isFinanced',
  IsHighRisk = 'IsHighRisk',
  Count = 'count',
  Debt = 'debt',
}

export enum IndustrySortBy {
  Id = 'id',
  Name = 'name',
  ParameterEstimation = 'parameterEstimation',
  Raks = 'parameterEstimation',
}

export type IndustryFilter = {
  name?: string;
  parameterEstimation?: boolean;
  raks?: boolean;
};

export type IndustrySpecializationFilter = {
  industryName?: string;
  code?: string;
  description?: string;
  isLicensed?: boolean;
  isFinanced?: boolean;
  isHighRisk?: boolean;
};

export type QuotaNomenclaturePrice = {
  code: string;
  category?: string;
  name: string;
  count: number;
  price: number;
  monthNumber: number;
  averagePriceDrop: number;
};

export type NomenclaturePriceDrop = {
  monthNumber: number;
  mainDebt: number;
  priceDropAmount: number;
  priceDropPercents: number;
  priceDropValue: number;
};

export type QuotaNomenclaturePriceDrop = {
  price: QuotaNomenclaturePrice;
  drops: NomenclaturePriceDrop[];
};

export type QuotaNomenclaturePriceDrops = {
  averagePriceDrop: number;
  drops: QuotaNomenclaturePriceDrop[];
};

export type AddIndustryRequest = Omit<IndustryViewModel, 'id'>;

export type EditIndustryRequest = AddIndustryRequest;

export type AddIndustrySpecializationRequest = Omit<
  IndustrySpecializationViewModel,
  'id' | 'industryName'
> & {
  industryId: number;
};

export type EditIndustrySpecializationRequest = AddIndustrySpecializationRequest;

export type IndustryLeasingProductViewModel = {
  id: number;
  name: string;
};

export type IndustryLeasingProductPagedList = PagedList<IndustryLeasingProductViewModel>;

export type IndustryLeasingProductFilter = {
  name?: string;
};

export enum IndustryLeasingProductSortBy {
  Id = 'id',
  Name = 'name',
}

export type IndustryLeasingProductSorting = {
  sortBy: IndustryLeasingProductSortBy;
  order: SortOrder;
};

export type AddIndustryLeasingProductRequest = Omit<IndustryLeasingProductViewModel, 'id'>;

export type EditIndustryLeasingProductRequest = AddIndustryLeasingProductRequest;

export type CounterpartyRatingAnswersViewModel = {
  answer1?: RatingAnswer1;
  answer2?: RatingAnswer2;
  answer3?: RatingAnswer3;
  answer4?: RatingAnswer4;
  answer5: RatingAnswer5;
  answer6: RatingAnswer6;
};

export enum CountryRiskLevel {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export type CountryRiskFilter = {
  name?: string;
};

export enum CountryRiskSortBy {
  Id = 'id',
  Name = 'name',
  BlackListRiskLevel = 'blackListRiskLevel',
  GreyListRiskLevel = 'greyListRiskLevel',
  SanctionsRiskLevel = 'sanctionsRiskLevel',
  CorruptionRiskLevel = 'corruptionRiskLevel',
  DrugsRiskLevel = 'drugsRiskLevel',
  TerrorRiskLevel = 'terrorRiskLevel',
  TerrorCountryRiskLevel = 'terrorCountryRiskLevel',
  PreferentialRiskLevel = 'preferentialRiskLevel',
  SpecialEconomicMeasuresRiskLevel = 'specialEconomicMeasuresRiskLevel',
}

export type CountryRiskSorting = {
  sortBy: CountryRiskSortBy;
  order: SortOrder;
};

export type CountryRiskViewModel = {
  id: number;
  name: string;
  blackListRiskLevel: CountryRiskLevel;
  greyListRiskLevel: CountryRiskLevel;
  sanctionsRiskLevel: CountryRiskLevel;
  corruptionRiskLevel: CountryRiskLevel;
  drugsRiskLevel: CountryRiskLevel;
  terrorRiskLevel: CountryRiskLevel;
  terrorCountryRiskLevel: CountryRiskLevel;
  preferentialRiskLevel: CountryRiskLevel;
  specialEconomicMeasuresRiskLevel: CountryRiskLevel;
  maxRiskLevel: CountryRiskLevel;
};

export type CountryRiskPagedList = PagedList<CountryRiskViewModel>;

export type AddCountryRiskRequest = Omit<CountryRiskViewModel, 'id' | 'maxRiskLevel'>;

export type EditCountryRiskRequest = AddCountryRiskRequest;

export type CounterpartyIndustryViewModel = {
  isFinanced: boolean;
  isEstimated: boolean;
};

export type IssueNotificationSettingViewModel = {
  issueType: IssueType;
  notificationType?: string;
  emails: string[];
};

export type IssueNotificationSettingSaveRequest = IssueNotificationSettingViewModel[];

export type ScoringRuleDescription = {
  descriptionDisplayMessage: string;
  expectedDisplayMessage: string;
  actualDisplayMessage: string;
  serviceMessages: string[];
};

export type ScoringRuleResultViewModel = {
  code: string;
  name: string;
  isValid: boolean;
  value: number;
  description: ScoringRuleDescription;
};

export type ScoringModelResult = {
  isValid: boolean;
  message: string;
  rules?: ScoringRuleResultViewModel[];
  file?: IssueFileViewModel;
};

export enum ScoringModelApprovalStatus {
  inProgress = 'inProgress',
  approved = 'approved',
  cancelled = 'cancelled',
  rejected = 'rejected',
  sentToRework = 'sentToRework',
  outdated = 'outdated',
}

export enum ScoringModelId {
  Excel = 0,
  Box = 1,
  Retail = 2,
  Manual = 10000,
}

export enum DealerIdentifyStatus {
  unknown = 'unknown',
  red = 'red',
  yellow = 'yellow',
  green = 'green',
}

export enum RatingRiskType {
  none = 'none',
  low = 'low',
  medium = 'medium',
  high = 'high',
  unacceptable = 'unacceptable',
}

export type RiskTypeViewModel = {
  id: number;
  name: string;
  ratingType: RatingRiskType;
  isTotal: boolean;
};

export type RiskTypeHistoryItemViewModel = {
  ratingType: RatingRiskType;
  changeDate: string;
  userId: string;
  userName: string;
};

export enum EventNotificationType {
  QuotaCounterpartyWithoutPodFT = 'quotaCounterpartyWithoutPodFT',
  RequestToIS = 'requestToIS',
  IssueContractIncident = 'issueContractIncident',
  QuotaWithContractLost = 'quotaWithContractLost',
}

export type EventNotificationSettingViewModel = {
  type: EventNotificationType;
  emails: string[];
};

export type EventNotificationSettingSaveRequest = EventNotificationSettingViewModel[];

export enum ContractPaymentConditionType {
  None = 'none',
  AfterObligation = 'afterObligation',
  UPD = 'upd',
  WrittenNotice = 'writtenNotice',
}

export enum RatingWarningType {
  None = 'none',
  MultipleActive = 'multipleActive',
  TimeOff = 'timeOff',
  SoonTimeOff = 'soonTimeOff ',
}

export type ExternalQuotaCounterparty = {
  inn: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  region?: string;
};

export type ExternalQuotaInput = {
  calculationMethod: CalculationMethod;
  price: number;
  prepayment?: Amount;
  subsidy?: Amount;
  numberOfMonths: number;
  currencyExchangeRate: CurrencyExchangeRate;
  nomenclature: string;
  lessee: ExternalQuotaCounterparty;
  dealer: ExternalQuotaCounterparty;
  seasonalPaymentOptions?: SeasonalPaymentOptions;
  paymentOptions?: MonthPaymentOption[];
};

export type CalcResult = {
  annualRise: number;
  monthPayments: number[];
  leaseAmount: number;
  possibleSavings: number;
};

export enum ExternalQuotaStatus {
  Created = 'created',
  InProgress = 'inProgress',
}

export type ExternalQuotaCalculation = {
  id: string;
  nomenclature: string;
  input: ExternalQuotaInput;
  lessee?: ExternalQuotaCounterparty;
  dealer?: ExternalQuotaCounterparty;
  status: ExternalQuotaStatus;
  createdDate: string;
  user?: UserViewModel;
  quotaId?: number;
  client: UserViewModel;
  requestType: string;
};

export enum ExternalQuotaFilterStatus {
  Request = 'request',
  Quota = 'quota',
  All = 'all',
}

export type ExternalQuotaFilter = {
  clientId?: string[];
  lesseeInn?: string[];
  dealerInn?: string[];
  status?: ExternalQuotaFilterStatus;
  userId?: UserViewModel[];
  tabId?: ExternalQuotaTab;
  region?: string[];
};

export enum ExternalQuotaSortBy {
  CreatedDate = 'createdDate',
}

export type ExternalQuotaSorting = {
  sortBy: ExternalQuotaSortBy;
  order: SortOrder;
};

export type ExternalQuotasPagedList = PagedList<ExternalQuotaCalculation>;

export type IssueContractIncidentRequest = {
  issueId: number;
  incidentText: string;
};

export type DefaultTemplateViewModel = {
  id: number;
  name: string;
};

export type ExternalClientItemViewModel = {
  clientApplicationId: string;
  clientName: string;
  template: TemplateViewModel;
};

export type ExternalClientTemplateViewModel = {
  clientApplicationId: string;
  templateId: number;
};

export type IssueControlDashboardItem = {
  text: string;
  value: boolean;
};

export type ScoringIssueDashboardItem = {
  id: number;
  assignName: string;
  authorName: string;
  leaseeName: string;
  issueControls: IssueControlDashboardItem[];
};

export type ScoringIssueDashboardModel = {
  items: ScoringIssueDashboardItem[];
  unsolvedScoringIssuesCount: number[];
};

export enum ScoringModelType {
  None = 'none',
  SevenMillion = 'sevenMillion',
  Retail = 'retail',
  Excel = 'excel',
  Manual = 'manual',
}

export enum DealQualificationGroup {
  General = 'general',
  Counterparties = 'counterparties',
  ContractBase = 'contractBase',
  LeaseCalculation = 'leaseCalculation',
  PaymentAndDeliveryOrder = 'paymentAndDeliveryOrder',
}

export enum DealQualificationType {
  Manual = 'manual',
  NoLimit = 'noLimit',
  Rule = 'rule',
}

export type DealQualificationRuleViewModel = {
  id: number;
  name: string;
  group: DealQualificationGroup;
  type: DealQualificationType;
  rule?: DealQualificationScoringModelRuleViewModel;
};

export type DealQualificationScoringModelRuleViewModel = {
  modelId: number;
  modelName: number;
  code: string;
  name: string;
  displayName: string;
};

export type DealQualificationAvailableRuleViewModel = {
  id: string;
  name: string;
  scoringModelId: number;
  ruleCode: string;
};

export type QuotaDealQualificationRuleViewModel = DealQualificationRuleViewModel & {
  isValid: boolean;
  isQualified: boolean;
};

export type AddDealQualificationRuleRequest = {
  name: string;
  group: DealQualificationGroup;
  type: DealQualificationType;
  scoringModelId?: number;
  ruleCode?: string;
};
export type UpdateDealQualificationRuleRequest = AddDealQualificationRuleRequest;

export type ScoringApprovalIssueViewModel = {
  id: number;
  status: IssueStatus;
  creditDecisionStatus?: CreditDecisionStatus;
  author: UserViewModel;
  assignee?: UserViewModel | null;
  file?: IssueFileViewModel | null;
};

export type ScoringApprovalViewModel = {
  id: number;
  name: string;
  isAutomatic: boolean;
  createdDate: string;
  result: ScoringModelResultViewModel;
  issue?: ScoringApprovalIssueViewModel;
};

export type ScoringModelResultViewModel = {
  isValid: boolean;
  message: string;
  rules?: ScoringModelRuleResultViewModel[] | null;
};

export type ScoringModelRuleResultDescription = {
  descriptionDisplayMessage: string;
  expectedDisplayMessage: string;
  actualDisplayMessage: string;
  serviceMessages: string[];
};

export type ScoringModelRuleResultViewModel = {
  code: string;
  name: string;
  isValid: boolean;
  value: number;
  description: ScoringModelRuleResultDescription;
};

export type AvailableScoringModelRuleViewModel = {
  id: string;
  name: string;
  scoringModelId: number;
  ruleCode: string;
};

export type DealQualificationFilter = {
  search?: string;
};

export enum DealQualificationSortBy {
  Group = 'group',
  Name = 'name',
  Type = 'type',
  Rule = 'rule',
}

export type DealQualificationSorting = {
  by: DealQualificationSortBy;
  order: SortOrder;
};

export type DealQualificationRulePagedList = PagedList<DealQualificationRuleViewModel>;

export type QuotaDealQualificationViewModel = {
  id: number;
  isValid: boolean;
};

export type ApproveMarginIssueViewModel = {
  margin: number;
};

export type MarginIssueActions = {
  canApproveRed: boolean;
  canApproveYellow: boolean;
  canRequestApproval: boolean;
};

export type QuotaMarginViewModel = {
  quotaId: number;
  min: number;
  max: number;
  current: number;
  calculated?: number;
  minGreen?: number;
  isLocked: boolean;
  isStartup?: boolean;
  lesseeInn?: string;
  marginIssue?: IssueViewModel;
  contractIssue?: IssueViewModel;
  marginIssueActions: MarginIssueActions;
  users: UserViewModel[];
};

export enum ScoringRouteAction {
  Select = 'select',
  Approve = 'approve',
  SendToExecution = 'sendToExecution',
}

export type ScoringRouteViewModel = {
  isLocked: boolean;
  approvals: ScoringApprovalViewModel[];
  scoringIssue?: IssueViewModel;
  scoringApprovalIssue?: IssueViewModel;
  contractIssue?: IssueViewModel;
  selectedScoringModelId?: number;
  availableAction?: ScoringRouteAction;
};

export enum RatingRiskIssueCounterpartyType {
  Lessee = 'lessee',
  Dealer = 'dealer',
}

export type RatingRiskViewModel = {
  inn: string;
  counterpartyType: RatingRiskIssueCounterpartyType;
  documentUrl: string;
};

export type CounterpartyDataViewModel = {
  inn: string;
  documentUrl: string;
};

export type QuotaCounterpartyInnViewModel = {
  inn: string;
  documentUrl: string;
};

export type QuotaCounterpartyViewModel = {
  inn: string;
  name: string;
  counterpartyType: RatingRiskIssueCounterpartyType;
  ratingRiskIssue?: IssueViewModel;
  canRequestRatingRisk: boolean;
  totalRisk: RatingRiskType;
  totalRiskDate?: string;
};

export type KonturStatistic = {
  methodName: string;
  periodStartDate: string;
  periodEndDate: string;
  limit: number;
  spent: number;
};
